/* jshint ignore:start */
(function($,sr){

  // debouncing function from John Hann
  // http://unscriptable.com/index.php/2009/03/20/debouncing-javascript-methods/
  var debounce = function (func, threshold, execAsap) {
      var timeout;

      return function debounced () {
          var obj = this, args = arguments;
          function delayed () {
              if (!execAsap)
                  func.apply(obj, args);
              timeout = null;
          };

          if (timeout)
              clearTimeout(timeout);
          else if (execAsap)
              func.apply(obj, args);

          timeout = setTimeout(delayed, threshold || 100);
      };
  }
  // smartresize
  jQuery.fn[sr] = function(fn){  return fn ? this.bind('resize', debounce(fn)) : this.trigger(sr); };

})(jQuery,'smartresize');

/* Taquiri Google Map Plugin */

(function($){
    $.fn.tqMap = function (locations, options) {
        "use strict";

        // Google Maps
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.src = "//maps.google.com/maps/api/js?key=AIzaSyCOW5cgJuy7UVvP1pN4j3LsFjYR16W-Gj0&callback=tqMapInit";
        document.body.appendChild(script);

        var $container = $(this),
            $window = $(window);

        window.tqMapInit = function() { init() };

        function init() {

            var settings = $.extend({
                scrollwheel: false,
                zoomControl: false,
                streetViewControl:false,
                scaleControl:true,
                zoom: 16,
                draggable: false,
                disableDefaultUI: true,
                styles: [
                    {
                        "featureType": "water",
                        "elementType": "labels",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                ]
            }, options );

            var map = new google.maps.Map($container[0], settings),
                bounds = new google.maps.LatLngBounds(),
                infowindow = new google.maps.InfoWindow({ content: "loading..." }),
                position, marker, i;

            $.each(locations, function(i, loc) {

                if ( loc.lat && loc.lng ){
                    var route = loc.route || "https://maps.google.com?saddr=Current+Location&daddr="+loc.lat+","+loc.lng,
                        content = '<div class="infoWindow"><strong>'+loc.name+'</strong><br>'+loc.addr+'<br><a href="'+route+'"><i class="fa fa-road"></i> Routenplaner</a></div>';

                    position = new google.maps.LatLng(loc.lat, loc.lng);

                    marker = new google.maps.Marker({
                        position: position,
                        title: loc.name,
                        icon: loc.icon || undefined,
                        map: map
                    });

                    if ( locations.length > 1 ) {
                        bounds.extend(position);
                        map.fitBounds(bounds);
                        $window.smartresize(function(){
                            map.fitBounds(bounds);
                        });
                    } else {
                        map.setCenter(position);
                        google.maps.event.addListenerOnce(map, 'tilesloaded', function() {
                            infowindow.setContent(content);
                            infowindow.open(map, marker);
                        });
                        $window.smartresize(function(){
                            map.setCenter(position);
                        });
                    }

                    google.maps.event.addListener(marker, 'click', function() {
                        infowindow.setContent(content);
                        infowindow.open(map, this);
                    });

                }

            });

        }

    }
})(jQuery);
/* jshint ignore:end */