/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        //*Dropdown menü*//
          

        if(!Cookies.get('cookieuse')) {
          $('#cookie-box').show();
        }
        $('#cookie-box .cookie-link').on('click', function() {
          $('#cookie-box').hide();
          Cookies.set('cookieuse', 1);
        });

        $('.menu-item-has-children').hover(function() {
          $(this).addClass('open');
        }, function() {
          $(this).removeClass('open');
        });

        //Sroll to Contact

      $("#btn_contact").click(function() {
    $('html, body').animate({
        scrollTop: $("#kontakt").offset().top
    }, 2000);

    });

     
         //* Tracking E-Mail */

      $('body').on('click', '.mail-event', function( e ) {     
          alert('Mail Tracking');
         return true; 
       });

      //* Tracking Telefon */

      $('body').on('click', '.call-event', function( e ) {     
          alert('Telefon Tracking');
         return true; 
       });


      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page


        //*Maps*//
        var locations = [ 
          {
           name: 'Sectari Consulting',
           addr: 'Im Brauereiviertel 5, 24118 Kiel',
           lat: 54.338831,
           lng: 10.132992,
          },
        ];

        $("#mapWrapper").tqMap(locations);
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    'referenzen': {
      init: function() {
        // JavaScript to be fired on the home page
          // init Isotope
       
        var words = [
          {text: "Sectari", weight: 13},
          {text: "offen", weight: 10.5},
          {text: "direkt", weight: 9.4},
          {text: "verlässlich", weight: 8},
          {text: "flexibel", weight: 6.2},
          {text: "transparent", weight: 5},
          {text: "kompetent", weight: 5},
          {text: "erfolgreich", weight: 9.4},
          {text: "zufrieden", weight: 6.2},
          {text: "professionell", weight: 5},
          {text: "aufmerksam", weight: 5},
          {text: "partnerschaftlich", weight: 5},
          {text: "fair", weight: 5},
          {text: "fokussiert", weight: 5},
          {text: "diskret", weight: 5},
          {text: "verantwortungsvoll", weight: 5},
          {text: "kreativ", weight: 5},
          {text: "lösungsorientiert", weight: 5},
          {text: "erfahren", weight: 5},
          {text: "vernetzt", weight: 5},
          {text: "kanalneutral", weight: 5},
          {text: "nachhaltig", weight: 5},
      
    
        ];

       $('#keywords').jQCloud(words, {
          width: 500,
          height: 350
        });


        
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    'kernkompetenzen': {
      init: function() {
        // JavaScript to be fired on the home page
          // init Isotope
       var words = [
          {text: "Sectari", weight: 13},
          {text: "offen", weight: 10.5},
          {text: "direkt", weight: 9.4},
          {text: "verlässlich", weight: 8},
          {text: "flexibel", weight: 6.2},
          {text: "transparent", weight: 5},
          {text: "kompetent", weight: 5},
          {text: "erfolgreich", weight: 9.4},
          {text: "zufrieden", weight: 6.2},
          {text: "professionell", weight: 5},
          {text: "aufmerksam", weight: 5},
          {text: "partnerschaftlich", weight: 5},
          {text: "fair", weight: 5},
          {text: "fokussiert", weight: 5},
          {text: "diskret", weight: 5},
          {text: "verantwortungsvoll", weight: 5},
          {text: "kreativ", weight: 5},
          {text: "lösungsorientiert", weight: 5},
          {text: "erfahren", weight: 5},
          {text: "vernetzt", weight: 5},
          {text: "kanalneutral", weight: 5},
          {text: "nachhaltig", weight: 5},
      
    
        ];
        

       $('#keywords').jQCloud(words, {
          width: 500,
          height: 350
        });


        
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    'anfahrt': {
      init: function() {
        // JavaScript to be fired on the home page


        //*Maps*//
        var locations = [ 
          {
           name: 'Sectari Consulting',
           addr: 'Im Brauereiviertel 5, 24118 Kiel',
           lat: 54.338831,
           lng: 10.132992,
          },
        ];

        $("#mapWrapper").tqMap(locations);
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about': {
      init: function() {
        // JavaScript to be fired on the about us page

       



      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
